import {AfterViewInit, Component, computed, ErrorHandler, HostListener, Inject, NgZone, OnDestroy, OnInit} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {Router} from '@angular/router';
import {ErrorHandlerService} from '@axiocode/error-handler';
import {BranchStore, BranchSwitcherService} from '@branch/data';
import {Hotkeys} from '@configuration';
import {DataLoaderService, InformationSystemStore, Loading} from '@information-system/data';
import {HotkeysService} from '@ngneat/hotkeys';
import {TranslateService} from '@ngx-translate/core';
import {TitleService} from '@seo';
import {BreadcrumbService} from '@ui/breadcrumb';
import {DialogHandle, DialogService} from '@ui/dialog';
import {HotkeysDialogComponent} from '@ui/hotkeys';
import {NavigationPlanService} from '@ui/navigation-plan';
import {NotificationService} from '@ui/notification';
import {ScrollingService} from '@ui/scrolling';
import {AuthStore} from '@user/auth';
import {UserStore} from '@user/data';
import {PermissionService} from '@user/security';
import {VersionableApiType, convertType} from '@versionable/data';
import {Observable, iif, map, of, switchMap, tap} from 'rxjs';
import {SubSink} from 'subsink';

type MentionType = VersionableApiType | 'actor' | 'usecase' | 'applicationcomponent' | 'application' | 'glossaryterm';

@Component({
    selector: 'mgcn-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    isLoggedIn$: Observable<boolean>;
    isLoadingApplication$: Observable<Loading>;
    #dialogHandle?: DialogHandle = undefined;
    #subs = new SubSink();

    currentIS = toSignal(this.ISStore.selectSelectedEntity$);
    showSidebar = computed(() => undefined !== this.currentIS());

    constructor(
        private router: Router,
        private authStore: AuthStore,
        private userStore: UserStore,
        private ISStore: InformationSystemStore,
        private branchStore: BranchStore,
        private ngZone: NgZone,
        private loaderService: DataLoaderService,
        private branchSwitcherService: BranchSwitcherService,
        private translator: TranslateService,
        private notificationService: NotificationService,
        private permissionService: PermissionService,
        private titleService: TitleService,
        private breadcrumbService: BreadcrumbService,
        private navigationService: NavigationPlanService,
        private hotkeys: HotkeysService,
        private dialogService: DialogService,
        private scrollingService: ScrollingService,
        @Inject(ErrorHandler) private errorHandlerService: ErrorHandlerService
    ) {
        this.isLoggedIn$ = this.authStore.selectIsLoggedIn$;
        this.isLoadingApplication$ = this.loaderService.isLoading$;
        this.userStore.initialize();

        // This displays errors, no matter where they happen
        this.#subs.sink = this.errorHandlerService.error$
            .pipe(
                // @todo filtrer et traiter les erreurs selon le type (+ context?) et pas juste print les messages API
                map(error => {
                    if (error.formErrors.length) {
                        error.message = 'ERROR.FORM_ERROR';

                        return {error, translate: true};
                    }

                    return {error, translate: error.translate};
                }),
                tap(({error, translate}) => this.notificationService.notifyError(error.message, undefined, translate))
            )
            .subscribe();
        this.#subs.sink = this.loaderService.error$
            .pipe(tap(error => this.notificationService.notifyError(error.message)))
            .subscribe();

        // This is triggered when the current branch changed
        this.#subs.sink = this.branchSwitcherService.branchChanged$.pipe(
            // Either redirect if the branch is unknown or load data from the branch if it is
            switchMap(branch => this.loaderService.load(branch.informationSystem.id, branch.id))
        ).subscribe();
        // This is triggered when the branch in local storage has changed in another tab
        this.#subs.sink = this.branchStore.storedBranchChanged$.pipe(
            switchMap(branch => iif(
                () => branch.isMainBranch,
                this.translator.get('BRANCH.MAIN_BRANCH'),
                of(branch.name)
            )),
            tap(branchName => this.notificationService.notify('info', 'BRANCH.ALERT_BRANCH_CHANGED_OTHER_TAB', undefined, true, {
                'branch': branchName
            })),
        ).subscribe();

        this.titleService.initialize();
        this.breadcrumbService.initialize();
        this.navigationService.initialize();
    }

    /** @ignore */
    ngOnInit(): void {
        // @ts-ignore
        window['angularManagicianComponentReference'] = {
            component: this,
            zone: this.ngZone,
            openEditionDrawer: (id: string, entity: 'datamodel' | 'relation') => this.openEditionPreview(id, entity),
            openMentionPreviewDrawer: (
                id: string,
                entity: MentionType
            ) => this.openMentionPreview(id, entity),
        };
    }

    ngAfterViewInit(): void {
        this.hotkeys.setSequenceDebounce(400);
        this.#subs.sink = this.hotkeys.addShortcut({
            keys: Hotkeys.ESC_DIALOG,
            group: 'KEYMAP.GROUP.GENERAL',
            description: 'KEYMAP.ESC_DIALOG',
            allowIn: ['INPUT', 'SELECT', 'TEXTAREA']
        }).subscribe(() => this.dialogService.close());
        this.hotkeys.registerHelpModal(() => {
            if (this.#dialogHandle) {
                this.#dialogHandle.close();
            }
            this.#dialogHandle = this.dialogService.open(HotkeysDialogComponent);
        });
    }

    ngOnDestroy(): void {
        this.#subs.unsubscribe();
    }

    @HostListener('document:scroll', ['$event'])
    onScroll(): void {
        const scrollY = window.scrollY;
        this.scrollingService.contentScrollEvent(scrollY);
    }

    private openMentionPreview(
        id: string,
        entity: MentionType
    ): void {
        switch (entity) {
            case 'actor':
            case 'applicationcomponent':
            case 'application':
            case 'glossaryterm':
            case 'usecase':
                this.router.navigate([
                    '',
                    {
                        outlets: {drawer: ['preview', entity, id]},
                    },
                ]);
                break;

            default:
                this.router.navigate([
                    '',
                    {
                        outlets: {drawer: ['preview', convertType(entity), id]},
                    },
                ]);
                break;
        }
    }

    private openEditionPreview(id: string, entity: 'datamodel' | 'relation'): void {
        const informationSystem = this.currentIS();
        if (informationSystem && !this.permissionService.isGranted({
            target: informationSystem,
            permission: 'write',
            type: 'is'
        })) {
            return;
        }

        if ('datamodel' === entity) {
            this.router.navigate([
                '',
                {
                    outlets: {drawer: ['preview', entity, 'quickedit', id]},
                },
            ]);
        } else if ('relation' === entity) {
            this.router.navigate([
                '',
                {
                    outlets: {drawer: ['preview', entity, 'relationedit', id]},
                },
            ]);
        }
    }
}
